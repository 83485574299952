import React from "react";
import { graphql } from "gatsby";
import SectionSeparator from "../components/layout/section-separator";
import TestimonialSection from "../components/layout/testimonial-section";
import DestinationsBoxSection from "../components/destinations/destinations-box-section";
import IntegrationFilterSection from "../components/integrations/integrations-filter-section";
import WhyChooseStitch from "../components/layout/why-choose-stitch";
import IntegrationsToolsCallout from "../components/integrations/integrations-tools-callout";
import IntegrationIntegrationFeatureSection from "../components/integrations/integration-integration-feature-section";
import CTASection from "../components/misc/cta-section";
import AnalyticsBlocks from "../components/integrations/analytics-blocks";

import Layout from "../components/layout/layout";
import Seo from "../components/misc/seo";

const IntegrationvsIntegration = ({ data, pageContext }) => {
    const menus = pageContext.menus?.items;
    const integration1 = data.integrationItem1;
    const integration2 = data.integrationItem2;
    const bodyClasses = `${pageContext.type} integrations integrations_${integration1.url} integrations_${integration1.url}_${integration2.url}`;

    const pageHeading = `Join your ${integration1.name} and ${integration2.name} data`;
    const pageSubHeading = `Stitch can replicate data from all your sources (including ${integration1.name} and ${integration2.name}) to a central warehouse. From there, it's easy to perform the in-depth analysis you need.`;
    const customerQuote = integration1.customer ?? integration2.customer;

    return (
        <Layout
            menus={menus}
            headerType={pageContext.type}
            headerItems={[integration1, integration2]}
            pageHeading={pageHeading}
            pageSubHeading={pageSubHeading}
        >
            <Seo
                title={`Join your ${integration1.name} and ${integration2.name} data in minutes`}
                description={`Join your ${integration1.name} and ${integration2.name} data in minutes without the headache of writing and maintaining ETL scripts.`}
                slug={`/integrations/${integration1.url}/${integration2.url}`}
                bodyClass={bodyClasses}
            />
            <SectionSeparator />
            <IntegrationIntegrationFeatureSection
                heading={`Two powerful tools, together`}
                subHeading={`Integrate ${integration1.name} and ${integration2.name} to turn your data into actionable insights.`}
                integration1={integration1}
                integration2={integration2}
            />
            <SectionSeparator />
            <AnalyticsBlocks integration={integration1} />
            <DestinationsBoxSection
                heading={`${integration1.name} and ${integration2.name} to your data warehouse in minutes`}
                paragraph={`Stitch delivers all your data to the leading data lakes, warehouses, and storage platforms.`}
                note={false}
            />
            <IntegrationsToolsCallout containerClasses={`st-section--border tools-section`} />
            {customerQuote ? (
                <TestimonialSection
                    quote={customerQuote[0].quote.quote}
                    author={customerQuote[0].quote_name}
                    position={`${customerQuote[0].quote_title}, ${customerQuote[0].company_name}`}
                />
            ) : (
                <TestimonialSection
                    quote={`The best part? Zero engineering or ongoing maintenance. It's a no-brainer for Stitch to handle our data pipelines while our teams stay focused on our core business and growth.`}
                    author={`Dave Riggs`}
                    position={`Director of Acquisition Marketing, Laurel & Worf`}
                />
            )}

            <SectionSeparator />
            <WhyChooseStitch />
            <CTASection
                heading={`Integrate your ${integration1.name} and ${integration2.name} data today`}
                subheading={`Select your integrations, choose your warehouse, and enjoy Stitch free for 14 days.`}
                dualCTA={true}
                containerClasses={`st-section--lowlight`}
            />

            <IntegrationFilterSection
                heading={`Connect to your ecosystem of data sources`}
                paragraph={`Stitch integrates with leading databases and SaaS products. No API maintenance, ever, while you maintain full control over replication behavior.`}
                currentIntegrationName={integration1.name}
            />
        </Layout>
    );
};

export const query = graphql`
    query($integration1: String!, $integration2: String!) {
        integrationItem1: contentfulIntegration(url: { eq: $integration1 }) {
            contentful_id
            name
            url
            status
            microversename
            premium_integration
            short_description
            integration_category {
                name
            }
            logo {
                file {
                    url
                }
            }
            square_logo {
                file {
                    url
                }
            }
            cropped_logo {
                file {
                    url
                }
            }
            documentation_link
            gitHubRepository
            endpoints {
                integration_name
                schema_name
                description {
                    description
                }
            }
            generate_microverse_pages
            customer {
                company_name
                quote_name
                quote_title
                quote {
                    quote
                }
            }
            analytics_block {
                analytics_block_title
                analytics_package
                block_author
                block_published_date
                contentful_id
                description {
                    description
                }
                integrationName
                link_to_source
                code_snippet {
                    code_snippet
                }
            }
        }
        integrationItem2: contentfulIntegration(url: { eq: $integration2 }) {
            contentful_id
            name
            url
            status
            microversename
            premium_integration
            short_description
            integration_category {
                name
            }
            logo {
                file {
                    url
                }
            }
            square_logo {
                file {
                    url
                }
            }
            cropped_logo {
                file {
                    url
                }
            }
            documentation_link
            gitHubRepository
            endpoints {
                integration_name
                schema_name
                description {
                    description
                }
            }
            generate_microverse_pages
            customer {
                company_name
                quote_name
                quote_title
                quote {
                    quote
                }
            }
            analytics_block {
                analytics_block_title
                analytics_package
                block_author
                block_published_date
                contentful_id
                description {
                    description
                }
                integrationName
                link_to_source
                code_snippet {
                    code_snippet
                }
            }
        }
    }
`;

export default IntegrationvsIntegration;
